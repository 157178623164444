/* Targeting the input selection background more specifically */
.ant-select .ant-select-selection-search-input::selection {
    background-color: #cfe8ff !important; /* Light blue background */
    color: #000 !important; /* Black text */
}

/* Input focus state */
.ant-select .ant-select-selection-search-input:focus {
    background-color: #e6f7ff !important; /* Light blue background */
    border-color: #40a9ff !important; /* Blue border */
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important; /* Glow effect */
}

/* Ensuring the background stays visible when the input is active */
.ant-select .ant-select-selection-search-input:active, 
.ant-select .ant-select-selection-search-input:focus-visible {
    background-color: #e6f7ff !important;
}
