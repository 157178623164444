/* Apply Space Grotesk font to the entire form */
.medicine-form-container {
    font-family: 'Space Grotesk', sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 0 auto;
}

.medicine-form-container h1 {
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.medicine-form-container .ant-form-item-label {
    font-weight: 500;
}

.medicine-form-container .ant-input,
.medicine-form-container .ant-select-selector {
    border-radius: 4px;
    height: 40px;
}

.medicine-form-container .ant-btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-weight: 500;
}

.medicine-form-container .ant-btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.medicine-form-container .ant-table {
    margin-top: 20px;
}

.medicine-form-container .ant-pagination {
    margin-top: 20px;
    text-align: center;
}
