/* Ensure the container covers the full width of the page */
.container {
    background-color: #ffffff; /* White background for the container */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for the container */
    margin-bottom: 20px; /* Add margin at the bottom */
    width: 100%; /* Full width of the container */
    box-sizing: border-box; /* Ensure padding and border are included in the width calculation */
    overflow-x: auto; /* Enable horizontal scrolling if content overflows */
}

.custom-table {
    width: 100%; /* Ensure the table takes up the full width of the container */
    max-width: 100%; /* Prevent the table from exceeding the container’s width */
    overflow-x: auto; /* Horizontal scrolling if necessary */
    border-collapse: collapse; /* Ensure borders are collapsed */
    background-color: #f9f9f9; /* Light background for the table */
}

.custom-table .ant-table {
    border: 1px solid #ddd; /* Full table border */
}

.custom-table .ant-table-thead > tr > th {
    background-color: #4CAF50; /* Green background for header */
    color: white; /* White text color for header */
    text-align: left; /* Left-align the header text */
    padding: 12px 15px; /* Add padding for better spacing */
    border-bottom: 2px solid #ddd; /* Stronger border for the header */
    font-size: 16px; /* Increase font size */
}

.custom-table .ant-table-tbody > tr > td {
    padding: 12px 15px; /* Add padding for better spacing */
    border-bottom: 1px solid #ddd; /* Borders for table cells */
    font-size: 14px; /* Increase font size */
}

.custom-table .ant-table-tbody > tr:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
}

.custom-table .ant-table-tbody > tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternate row colors */
}

.edit-button {
    background-color: #ffc107; /* Yellow color for Edit button */
    border-color: #ffc107;
    color: #000;
}

.edit-button:hover {
    background-color: #e0a800; /* Darker yellow on hover */
    border-color: #d39e00;
    color: #000;
}

.delete-button {
    background-color: #dc3545; /* Red color for Delete button */
    border-color: #dc3545;
    color: #fff;
}

.delete-button:hover {
    background-color: #c82333; /* Darker red on hover */
    border-color: #bd2130;
    color: #fff;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-new-button {
    background-color: #4CAF50; /* Green color for Add New button */
    border-color: #4CAF50;
    color: #fff;
}

.add-new-button:hover {
    background-color: #45a049; /* Darker green on hover */
    border-color: #45a049;
    color: #fff;
}
